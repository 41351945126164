<template>
    <div class="userCollect">
        <div class="userCollect_tab">
            <div @click="tabFun(1)" :class="type == 1?'active':''">专题</div>
            <div @click="tabFun(2)" :class="type == 2?'active':''">字体素材</div>
            <div @click="tabFun(3)" :class="type == 3?'active':''">视频教程</div>
        </div>
        <div class="list">
            <div class="userCollect_ul clearfix" v-if="list.length>0">
                <!-- wow animate__animated animate__fadeInUp -->
                <div class="fl userCollect_li " :style="{animationDelay:0.1*index+'s'}" v-for="(item,index) in list" :key="index" @click="toDetail(item)">
                    <div class="userCollect_li_box">
                        <div class="userCollect_li_top">
                            <img v-if="item.thumbs[0]" :src="item.thumbs[0]||''" alt="">
                        </div>
                        <div class="userCollect_li_bottom">
                            <div>
                                <img v-if="item.thumbs[1]" :src="item.thumbs[1]||''" alt="">
                            </div>
                            <div>
                                <img v-if="item.thumbs[2]" :src="item.thumbs[2]||''" alt="">
                            </div>
                            <div>
                                <img v-if="item.thumbs[3]" :src="item.thumbs[3]||''" alt="">
                            </div>
                        </div>
                        <div class="userCollect_li_mask"></div>
                        <img @click.stop="editFun(item.id)" v-if="is_benren == 1 && item.id != 0" class="userCollect_li_edit" src="../../assets/images/colloctEdit.png" alt="">
                    </div>
                    <div class="clearfix">
                        <div class="fl userCollect_li_l">
                            {{item.name}}
                        </div>
                        <div class="fr userCollect_li_r">{{item.total}}个作品</div>
                    </div>
                </div>
            </div>
            <el-empty v-else description="暂无数据"></el-empty>
            <!-- <page v-if="total>0" :page="page" :limit="limit" :total="total"></page> -->
        </div>
        <div class="mask" v-if="isShow">
            <favorite :id="id"></favorite>
        </div>
    </div>
</template>

<script>
// import { WOW } from 'wowjs'
import favorite from '../../components/favorite.vue'
import page from '../../components/page.vue'
export default {
    name:'userCollect',
    components:{
        favorite,
        // page
    },
    data(){
        return{
            list:[],

            // 分页
            total:0,//总数
            page:1,//页数
            limit:12,//每页个数

            // 收藏参数
            type:1,//类型
            id:'',//收藏id
            isShow:false,
            is_benren:1,
            userId:this.$route.query.userId
        }
    },
    mounted(){
        this.$parent.tab = 2;
        this.$parent.tabIndex = 2;
        this.$parent.$parent.routerIndex = 0;
        this.$parent.$parent.showIndex = 1;
        this.is_benren = this.$parent.is_benren;
        // new WOW().init();
        this.initFun();
    },
    methods:{
        // tabFun
        tabFun(el){
            this.type = el;
            this.initFun()
        },
        //收藏夹列表
        initFun(){
            var that = this;
            this.$api.POST(this.$face.favoritesIndex,{user_id: this.userId,type: this.type},function(res){
                that.list = res.data
            })
        },

        // xiangqing
        toDetail(row){
            this.$router.push({name:'userFavorite',query:{id:row.id,type:row.type,isFrom:this.type}})
        },

        // 收藏夹编辑
        editFun(id){
            this.id = id;
            this.isShow = true;
        },

        //关闭收藏
        closeFun(){
            this.isShow = false
        }
    }
}
</script>

<style scoped>
.userCollect{
    padding-top: 48px;
}
.userCollect_tab{
    margin-bottom: 31px;
    display: flex;
    align-items: center;
}
.userCollect_tab div{
    width: 100px;
	height: 40px;
    line-height: 40px;
    text-align: center;
	background-color: #ffffff;
	border-radius: 10px;
    font-size: 15px;
	color: #999999;
    margin-right: 33px;
    cursor: pointer;
}
.userCollect_tab div.active{
    background-color: #5957FF;
    color: #FFFFFF;
}
.userCollect_tab div:hover{
    background-color: #ebebff;
    color: #5957FF;
}


.list{
    padding-bottom: 56px;
}
.userCollect_ul{
    margin: 0 -10px 26px;
}
.userCollect_li{
    width: 335px;
    height: 374px;
    border-radius: 10px;
    margin:0 10px 20px;
    padding: 12px ;
    box-sizing: border-box;
    background-color: #FFF;
}
.userCollect_li_box{
    width: 100%;
    height: 312px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}
.userCollect_li_top{
    width: 100%;
    height: 196px;
	background-color: #ffffff;
	border-radius: 10px 10px 0px 0px;
    margin-bottom: 2px;
}
.userCollect_li_top img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s;
}
.userCollect_li_bottom{
    display: flex;
}
.userCollect_li_bottom div{
    width: 103px;
	height: 116px;
	background-color: #fafbfb;
    overflow: hidden;
    margin-right: 2px;
}
.userCollect_li_bottom div:first-child{
    border-radius: 0px 0px 0px 10px;
}
.userCollect_li_bottom div:last-child{
    margin-right: 0;
    border-radius: 0px 0px 10px 0px;
}
.userCollect_li_bottom div img{
    max-height: 116px;
    margin-left: calc(50% - 88px);
    object-fit: cover;
}
.userCollect_li_l{
    line-height: 41px;
    font-size: 16px;
	color: #333333;
}
.userCollect_li_r{
    font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 41px;
	letter-spacing: 0px;
	color: #999999;
}

.userCollect_li_mask{
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    border-radius: 10px;
    background: rgba(0,0,0,.4);
    opacity: 0;
    z-index: 33;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    cursor: default;
}
.userCollect_li_edit{
    width: 40px;
    height: 40px;
    position: absolute;
    top: -60px;
    right: 10px;
    opacity: 1;
    z-index: 34;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    cursor: pointer;
}
.userCollect_li:hover .userCollect_li_mask{
    opacity: 1;
}
.userCollect_li:hover .userCollect_li_edit{
    top: 16px;
    opacity: 1;
    transition-delay: 0.2s;
}
</style>